import React from 'react'
import PropTypes from 'prop-types'
import withTranslation from 'next-translate/withTranslation'

class NumberCount extends React.PureComponent {
  static shortenNumber (number, significantDigits = 6, options = {}, i18n) {
    const { t, lang: locale } = i18n
    const absolute = Math.abs(number)

    const dimensions = [
      {
        base: 1000000000,
        short: t('default:billionshorthand') || 'B'
      },
      {
        base: 1000000,
        short: t('default:millionshorthand') || 'M'
      },
      {
        base: 1000,
        short: t('default:thousandsshorthand') || 'K'
      },
      {
        base: 1,
        short: ''
      }
    ]

    const { base, short } = dimensions.find(d => absolute >= d.base * (Math.pow(10, significantDigits))) || { base: 0 }
    if (base) {
      const str = (Math.round(number / (base / 10)) / 10).toLocaleString(locale, Object.assign(options, {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }))
      return str.replace(/([0-9])(\s[^0-9]|$)/i, '$1' + short + '$2')
    } else {
      return number.toLocaleString(locale, options)
    }
  }

  render () {
    const { lang: locale } = this.props.i18n
    const formatted = this.props.number.toLocaleString(locale, this.props.options)
    const shortened = NumberCount.shortenNumber(this.props.number, this.props.significantDigits, this.props.options, this.props.i18n)
    return <span title={formatted + (this.props.title ? ' ' + this.props.title : '')}>{shortened}</span>
  }
}

NumberCount.propTypes = {
  number: PropTypes.number.isRequired,
  significantDigits: PropTypes.number,
  options: PropTypes.object,
  title: PropTypes.string
}

export default withTranslation(NumberCount)
