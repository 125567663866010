export const meta = {
  minYear: 1991,
  maxYear: 2020
}

export const sp500 = [
  { year: 1975, value: 31.55 },
  { year: 1976, value: 19.15 },
  { year: 1977, value: -11.5 },
  { year: 1978, value: 1.06 },
  { year: 1979, value: 12.31 },
  { year: 1980, value: 25.77 },
  { year: 1981, value: -9.73 },
  { year: 1982, value: 14.76 },
  { year: 1983, value: 17.27 },
  { year: 1984, value: 1.4 },
  { year: 1985, value: 26.33 },
  { year: 1986, value: 14.62 },
  { year: 1987, value: 2.03 },
  { year: 1988, value: 12.4 },
  { year: 1989, value: 27.25 },
  { year: 1990, value: -6.56 },
  { year: 1991, value: 26.31 },
  { year: 1992, value: 4.46 },
  { year: 1993, value: 7.06 },
  { year: 1994, value: -1.54 },
  { year: 1995, value: 34.11 },
  { year: 1996, value: 20.26 },
  { year: 1997, value: 31.01 },
  { year: 1998, value: 26.67 },
  { year: 1999, value: 19.53 },
  { year: 2000, value: -10.14 },
  { year: 2001, value: -13.04 },
  { year: 2002, value: -23.37 },
  { year: 2003, value: 26.38 },
  { year: 2004, value: 8.99 },
  { year: 2005, value: 3 },
  { year: 2006, value: 13.62 },
  { year: 2007, value: 3.53 },
  { year: 2008, value: -38.49 },
  { year: 2009, value: 23.45 },
  { year: 2010, value: 12.78 },
  { year: 2011, value: 0 },
  { year: 2012, value: 13.41 },
  { year: 2013, value: 29.6 },
  { year: 2014, value: 11.39 },
  { year: 2015, value: -0.73 },
  { year: 2016, value: 9.54 },
  { year: 2017, value: 19.42 },
  { year: 2018, value: -6.24 },
  { year: 2019, value: 28.88 },
  { year: 2020, value: 16.26 }
]

export const uraResidential = [
  { year: 1975, value: 0 },
  { year: 1976, value: 0.4 },
  { year: 1977, value: -0.4 },
  { year: 1978, value: 0.7 },
  { year: 1979, value: 2.9 },
  { year: 1980, value: 11.2 },
  { year: 1981, value: 8.3 },
  { year: 1982, value: 2.4 },
  { year: 1983, value: 2.9 },
  { year: 1984, value: -4.4 },
  { year: 1985, value: -5.0 },
  { year: 1986, value: -0.2 },
  { year: 1987, value: 4.1 },
  { year: 1988, value: 2 },
  { year: 1989, value: 4.3 },
  { year: 1990, value: 2.0 },
  { year: 1991, value: 7.3 },
  { year: 1992, value: 7.7 },
  { year: 1993, value: 19.8 },
  { year: 1994, value: 31.8 },
  { year: 1995, value: 11.0 },
  { year: 1996, value: 5.8 },
  { year: 1997, value: -15.3 },
  { year: 1998, value: -36.9 },
  { year: 1999, value: 24.4 },
  { year: 2000, value: -1.0 },
  { year: 2001, value: -11.1 },
  { year: 2002, value: -1.5 },
  { year: 2003, value: -1.7 },
  { year: 2004, value: 0.7 },
  { year: 2005, value: 3.2 },
  { year: 2006, value: 8.6 },
  { year: 2007, value: 29.0 },
  { year: 2008, value: -5.7 },
  { year: 2009, value: 2.0 },
  { year: 2010, value: 20.8 },
  { year: 2011, value: 8.2 },
  { year: 2012, value: 4.1 },
  { year: 2013, value: 1.7 },
  { year: 2014, value: -6.2 },
  { year: 2015, value: -5.4 },
  { year: 2016, value: -4.4 },
  { year: 2017, value: 1.5 },
  { year: 2018, value: 10.9 },
  { year: 2019, value: 4.0 },
  { year: 2020, value: -1.4 }
]

export const gold = [
  { year: 1975, value: -24.17 },
  { year: 1976, value: -3.85 },
  { year: 1977, value: 19.69 },
  { year: 1978, value: 29.55 },
  { year: 1979, value: 119.51 },
  { year: 1980, value: 30.25 },
  { year: 1981, value: -30.91 },
  { year: 1982, value: 8.31 },
  { year: 1983, value: -12.68 },
  { year: 1984, value: -17.65 },
  { year: 1985, value: 0.64 },
  { year: 1986, value: 21.51 },
  { year: 1987, value: 24.55 },
  { year: 1988, value: -13.93 },
  { year: 1989, value: -2.31 },
  { year: 1990, value: -7.71 },
  { year: 1991, value: -4.4 },
  { year: 1992, value: -7.28 },
  { year: 1993, value: 14.58 },
  { year: 1994, value: -1.14 },
  { year: 1995, value: 2.13 },
  { year: 1996, value: -4.75 },
  { year: 1997, value: -21.78 },
  { year: 1998, value: 1.07 },
  { year: 1999, value: -2.95 },
  { year: 2000, value: -4.1 },
  { year: 2001, value: 1.62 },
  { year: 2002, value: 20.33 },
  { year: 2003, value: 22.35 },
  { year: 2004, value: 8.86 },
  { year: 2005, value: 15.39 },
  { year: 2006, value: 23.39 },
  { year: 2007, value: 27.61 },
  { year: 2008, value: 8.29 },
  { year: 2009, value: 25.04 },
  { year: 2010, value: 29.24 },
  { year: 2011, value: 8.93 },
  { year: 2012, value: 8.26 },
  { year: 2013, value: -27.33 },
  { year: 2014, value: 0.12 },
  { year: 2015, value: -11.34 },
  { year: 2016, value: 7.71 },
  { year: 2017, value: 12.57 },
  { year: 2018, value: -1.15 },
  { year: 2019, value: 18.83 },
  { year: 2020, value: 24.43 }
]
