import { productUnitRates } from './resolveProductUnit'

const pastDate = new Date(1970, 0, 17)
const pastPrice = 34.9

export function historicGain (spot, fx) {
  const secondsSince = 31557600000 / (new Date() - pastDate)
  return {
    pastDate,
    pastPrice,
    secondsSince,
    gain: Math.pow((spot * productUnitRates.ounce / fx) / pastPrice, secondsSince) - 1
  }
}
